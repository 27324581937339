import { useQuery } from "react-query";
import { UserInfo } from "../types/userInfo";

const fetchUserInfo = async (key?: string): Promise<UserInfo> => {
  const data = {
    id: "1",
    avatar: "",
    email: "john@smith.com",
    firstName: "John",
    job: "Founder",
    lastName: "Smith",
    progress: 75,
    role: "Admin",
  };
  return data;
};

export function useUserInfo(key?: string) {
  return useQuery(["user-info", key], () => fetchUserInfo(key), {
    enabled: !!key,
  });
}
