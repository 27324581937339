import axios from "axios";
import { useMutation } from "react-query";
import { REACT_APP_MINTING_API_DOMAIN } from "../../core/config/api";

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<{ token: string }> => {
  const { data } = await axios.post(`${REACT_APP_MINTING_API_DOMAIN}/login`, {
    email,
    password,
  });
  return data;
};

export function useLogin() {
  const { isLoading, mutateAsync } = useMutation(login);

  return { isLoggingIn: isLoading, login: mutateAsync };
}
