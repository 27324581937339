import React, { createContext, useContext, useEffect, useState } from "react";

import * as cognito from '../libs/cognito'

import { useLocalStorage } from "../../core/hooks/useLocalStorage";
import { useLogin } from "../hooks/useLogin";
import { useLogout } from "../hooks/useLogout";
import { useUserInfo } from "../hooks/useUserInfo";
import { UserInfo } from "../types/userInfo";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

interface AuthContextInterface {
  hasRole: (roles?: string[]) => {};
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
  userInfo?: UserInfo;
}

export const AuthContext = createContext({} as AuthContextInterface);

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authKey, setAuthKey] = useLocalStorage<string>("authkey", "");

  const { isLoggingIn, login } = useLogin();
  const { isLoggingOut, logout } = useLogout();
  const { data: userInfo } = useUserInfo(authKey);

  // const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  // const [sessionInfo, setSessionInfo] = useState({})

  // useEffect(() => {
  //   async function getSessionInfo() {
  //     try {
  //       const session: any = await getSession()
  //       setSessionInfo({
  //         accessToken: session.accessToken.jwtToken,
  //         refreshToken: session.refreshToken.token,
  //       })

  //       setAuthKey(`${session.accessToken.jwtToken}`);
  //       //window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
  //       window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
  //       setAuthStatus(AuthStatus.SignedIn)
  //     } catch (err) {
  //       setAuthStatus(AuthStatus.SignedOut)
  //     }
  //   }
  //   getSessionInfo()
  // }, [setAuthStatus, authStatus, setAuthKey])

  const hasRole = (roles?: string[]) => {
    if (!roles || roles.length === 0) {
      return true;
    }
    if (!userInfo) {
      return false;
    }
    return roles.includes(userInfo.role);
  };

  const handleLogin = async (email: string, password: string) => {
    return login({ email, password })
      .then(({ token }) => {
        setAuthKey(token);
        return token;
      })
      .catch((err) => {
        throw err;
      });
  };

  // const handlePassword

  // const handleLogin = async (email: string, password: string) => {
  //   debugger;
  //   try {
  //     await cognito.signInWithEmail(email, password)
  //     setAuthStatus(AuthStatus.SignedIn)
  //   } catch (err: any) {
  //     if (err.code === 'NEW_PASSWORD_REQUIRED') {
  //       // link to the password change page then submit and login.
  //       console.log('PASSWORD NEEDS TO CHANGE');
  //       // return;
  //     }
  //     setAuthStatus(AuthStatus.SignedOut)
  //     throw err
  //   }
  // };

  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  const handleLogout = async () => {
    return logout()
      .then((data) => {
        setAuthKey("");
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  // const handleLogout = async () => {
  //   cognito.signOut()
  //   setAuthStatus(AuthStatus.SignedOut)
  // };

  return (
    <AuthContext.Provider
      value={{
        hasRole,
        isLoggingIn,
        isLoggingOut,
        login: handleLogin,
        logout: handleLogout,
        userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
