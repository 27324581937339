import Box, { BoxProps } from "@material-ui/core/Box";

type LogoProps = {
  colored?: boolean;
  size?: number;
} & BoxProps;

const Logo = ({ colored = false, size = 40, ...boxProps }: LogoProps) => {
  return (
    <Box {...boxProps}>
      <img src={`${process.env.PUBLIC_URL}/img/kora-labs-logo1x.png`} alt="Kora Labs" />
    </Box>
  );
};

export default Logo;
