import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./core/components/PrivateRoute";

// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));
const Dashboard = lazy(() => import("./admin/pages/ada-handle/Dashboard"));
const DailySales = lazy(() => import("./admin/pages/ada-handle/DailySales"));
const Wallets = lazy(() => import("./admin/pages/ada-handle/Wallets"));
const IncomeTaxes = lazy(() => import("./admin/pages/ada-handle/IncomeTaxes"));
const Home = lazy(() => import("./admin/pages/Home"));


// Auth
const Login = lazy(() => import("./auth/pages/Login"));

// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={`/login`} replace />} />
      <PrivateRoute path="admin" element={<Admin />}>
        <PrivateRoute path="/" element={<Home />} />
        <PrivateRoute path="ada-handle/dashboard" element={<Dashboard />} />
        <PrivateRoute path="ada-handle/daily-sales" element={<DailySales />} />
        <PrivateRoute path="ada-handle/wallets" element={<Wallets />} />
        <PrivateRoute path="ada-handle/income-taxes" element={<IncomeTaxes />} />
      </PrivateRoute>
      <Route path="login" element={<Login />} />
      <Route path="403" element={<Forbidden />} />
      <Route path="404" element={<NotFound />} />
      <Route
        path="*"
        element={<Navigate to={`/404`} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
